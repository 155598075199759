<template>
  <div class="tw-payment-form-stripe min-h-[320px]" :class="{ 'pointer-events-none': isEditorMode }">
    <div
      v-if="loadPage || isLoadingStripeForm || isGettingToken"
      class="flex h-full min-h-[320px] items-center justify-center py-24"
    >
      <WebSpinner
        size="24"
        color="transparent"
        background="currentColor"
        class="h-[27px]"
      />
    </div>
    <div id="link-authentication-element" class="mb-8" />
    <div id="payment-element" />

    <AcceptPolicy
      v-if="!isLoadingStripeForm && (isEditorMode || pageData.isPolicyRequired)"
      v-model="form.acceptPolicy"
      :page-options="pageOptions"
      :page-data="pageData"
      :locale="locale"
      :input-class="inputClass"
      :consent-style="consentStyle"
      :is-editor-mode="isEditorMode"
      class="mt-16"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, watch, type PropType, onBeforeUnmount } from 'vue';
import {
  loadStripe,
  type Stripe,
  type StripeElements,
  type Appearance,
  type StripeElementsOptionsClientSecret,
  type StripePaymentElement
} from '@stripe/stripe-js';
import { $wait } from '@shared/utils/wait';
import { WebSpinner } from '@shared/components';
import type { PageOptions, Element as ElementType } from '@shared/types/model';
import { type PaymentForm } from '@shared/elements/common/payment/types';
import { prepareFontUrl } from '@shared/utils';
import { debounce, globalEmit, globalListener } from '@shared/utils/helpers';
import { PaymentFormErrors, type PaymentPageData } from '../../types';
import AcceptPolicy from '../accept-policy/index.vue';

const props = defineProps({
  modelValue: {
    type: Object as PropType<Partial<PaymentForm>>,
    default: () => ({ acceptPolicy: false })
  },
  element: { type: Object as PropType<ElementType<'payment' | 'payment-with-tabs'>>, default: () => ({}) },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  isEditorMode: { type: Boolean, default: true },
  stripeToken: { type: Object as PropType<PaymentPageData['stripeToken']>, default: () => ({}) },
  consentStyle: { type: Object, default: () => ({}) }
});

const stripe = ref<Stripe | null>(null);
const elements = ref<StripeElements | null>(null);
const paymentElement = ref<StripePaymentElement | null>(null);
const loadPage = ref(true);
const isLoadingStripeForm = computed(() => $wait?.is(['loadStripeForm']));
const isGettingToken = computed(() => $wait?.is(['getPaymentToken']));
const form = computed(() => props.modelValue);

const updateElementOptions = debounce(() => {
  elements.value?.update(getElementsOptions());
}, 300);

watch(() => [props.pageOptions, props.element.options, props.element.style?.backgroundColor], updateElementOptions, {
  deep: true
});

watch(
  () => [props.element.options.layout, props.element.options.label?.fontFamily, props.pageOptions?.fontFamily],
  () => reloadStripeForm()
);

watch(
  () => props.stripeToken.initializeToken,
  () => loadStripeForm()
);

const inputClass = computed(() => ({
  'pointer-events-none': props.isEditorMode
}));

function getElementAppearance() {
  const options = props.element.options;
  const { colors, fontFamily } = props.pageOptions;

  return {
    variables: {
      borderRadius: '0.5rem',
      colorText: '#151b26',
      colorTextPlaceholder: '#818a9c',
      colorDanger: '#e13023',
      colorBackground: props.element.style?.backgroundColor || '#ffffff',
      fontSizeSm: '14px',
      focusBoxShadow: 'none',
      focusOutline: 'none',
      iconCardCvcErrorColor: '#e13023'
    },
    rules: {
      '.Input': {
        borderRadius: '0.5rem',
        fontSize: '14px',
        paddingLeft: '8px',
        paddingBottom: '11px',
        paddingTop: '11px',
        height: '40px',
        marginBottom: '4px',
        boxShadow: 'none',
        backgroundColor: '#ffffff'
      },
      '.Input--invalid': {
        boxShadow: 'none'
      },
      '.Input:focus': {
        borderColor: colors?.theme?.[0]
      },
      '.Input--invalid:focus': {
        boxShadow: 'none',
        borderColor: 'var(--colorDanger)'
      },
      '.Input--focused, .p-Input--focused': {
        outline: 'none',
        boxShadow: 'none',
        border: 'none'
      },
      '.Label': {
        display: 'none',
        marginBottom: '8px',
        fontWeight: options?.label?.textStyle?.bold ? 'bold' : '500',
        color: options?.label?.color || colors?.text?.[0] || 'var(--colorText)',
        fontSize: `${options?.label?.fontSize}px` || undefined,
        fontFamily: options?.label?.fontFamily || fontFamily || undefined,
        fontStyle: options.label?.textStyle?.italic ? 'italic' : undefined,
        textDecoration:
          [
            options.label?.textStyle?.underline && 'underline',
            options.label?.textStyle?.strikeThrough && 'line-through'
          ]
            .filter(Boolean)
            .join(' ') || undefined
      },
      '.Error': {
        marginTop: '8px',
        fontSize: '0.75rem'
      }
    }
  } as Appearance;
}

function getElementsOptions() {
  const { full: fontUrl } = prepareFontUrl([
    props.element.options.label?.fontFamily || props.pageOptions?.fontFamily || ''
  ]);

  return {
    locale: props.locale || undefined,
    clientSecret: props.stripeToken.token,
    appearance: getElementAppearance(),
    fonts: [{ cssSrc: fontUrl }]
  } as StripeElementsOptionsClientSecret;
}

function reloadStripeForm() {
  if (!(paymentElement.value as any)?._destroyed) {
    paymentElement.value?.off('loaderror');
    paymentElement.value?.off('ready');
    paymentElement.value?.destroy();
  }
  paymentElement.value =
    elements.value?.create('payment', {
      paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'paypal'],
      layout: props.element.options.layout || 'accordion'
    }) || null;
  paymentElement.value?.mount('#payment-element');
  paymentElement.value?.on('loaderror', function () {
    triggerIntegrationError(true);
  });
  paymentElement.value?.on('ready', function () {
    globalEmit('stripeLoaded');
  });
}

function triggerIntegrationError(hasError: boolean) {
  (props.pageData as any).paymentFormError = hasError
    ? PaymentFormErrors.STRIPE_LOAD_ERROR
    : PaymentFormErrors.NO_ERROR;
}

function setLoading(isLoading: boolean) {
  if (props.isEditorMode) return;
  (props.pageData as any).isLoading = isLoading;
}

async function loadStripeForm() {
  try {
    setLoading(true);
    loadPage.value = true;
    $wait?.start('loadStripeForm');
    if (!props.stripeToken.initializeToken) return false;
    stripe.value = await loadStripe(props.stripeToken.initializeToken);
    elements.value = stripe.value?.elements(getElementsOptions()) || null;
    reloadStripeForm();
  } finally {
    setLoading(false);
    loadPage.value = false;
    $wait?.end('loadStripeForm');
  }
}

globalListener('integrationRetryLoad', function () {
  if (props.pageData.paymentFormError === PaymentFormErrors.STRIPE_LOAD_ERROR) {
    triggerIntegrationError(false);
    loadStripeForm();
  }
});

onMounted(async () => {
  props.stripeToken.initializeToken && (await loadStripeForm());
  loadPage.value = false;
});

onBeforeUnmount(() => paymentElement.value?.destroy());

// Reach stripe from outside of form for submit
defineExpose({ stripe, elements });
</script>
